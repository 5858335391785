.coffeeLovely {
  transition: 0.5s ease;
  path {
    transition: 0.5s ease;
  }
  &:hover {
    transform: translate3d(0, -10px, 0);
    path {
      fill: #4a44f2;
    }
  }
}
