.swiperContainer {
  display: flex;
  align-items: center;

  margin-top: 2rem;
  padding: 2.5rem 0;

  .card {
    padding: 4rem 1.25rem;
    background-color: var(--primary);
    border-radius: 1.5rem;
    max-width: 280px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      width: 100%;
    }

    > div {
      max-width: 60%;
      margin: 0 auto;
    }

    img {
      width: 80%;
    }
  }
}