.social {
  transition: 0.5s ease;

  &:hover {
    transform: translate3d(0, -6px, 0);
    path {
      fill: var(--secondary);
    }
  }
}
