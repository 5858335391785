.solutionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem;

  .solution {
    background-color: var(--white-100);
    box-shadow: 0px 4px 8px 2px rgba(20, 20, 94, 0.08),
      -1px -1px 4px rgba(20, 20, 94, 0.08);

    border-radius: 0.75rem;
    padding: 2rem 1.5rem;
    max-width: 380px;
    height: 420px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    img {
      width: 80px;
      padding-bottom: 1rem;
    }

    span {
      color: var(--gray-400);
      font-size: 1.2rem;
      font-weight: 700;
    }

    p {
      color: var(--gray-200);
      line-height: 1.65rem;
    }

    transition: background-color 0.5s;

    &:hover {
      background-color: var(--primary);
      span,
      p {
        color: var(--white-200);
      }
    }
  }

  @media (max-width: 660px) {
    .solution {
      padding: 2rem 1rem;
      max-width: 320px;
      height: 380px;
    }
  }
}