.buttonContainer {
  font-weight: 600;

  color: #fff;
  background: linear-gradient(to right, #4949e6, #14145e);

  height: 2.75rem;
  padding: 0 32px;
  border: 0;
  border-radius: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.95;
    transform: scale(1.01);
  }
}

.isOutlined {
  background: transparent;

  border: 2px solid var(--primary);
}
