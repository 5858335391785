.stepListContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;

  gap: 0.5rem;

  .stepIcon {
    background: var(--primary);
    padding: 1rem;
    border-radius: 100%;
  }

  strong {
    font-size: 1.5rem;
  }

  span {
    text-align: center;
  }

  .swiper-pagination {
    margin-top: 5rem;
    background: #000 !important;
  }
}

.separator {
  border: 1px solid red;
  width: 100%;
}
