.container {
  max-width: var(--container-xl);
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 142px 24px;
}

.containerHero {
  max-width: var(--container-xl);
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 142px 24px;
}

.bgHero {
  position: absolute;
  top: 0;
  right: 0;

  z-index: -1;
  @media (max-width: 520px) {
    img {
      height: 720px;
    }
  }
}

.hero {
  height: 90vh;
  margin: 4rem auto !important;

  > div {
    h1 {
      color: var(--gray-600);
      font-weight: 700;
      font-size: 3rem ;
    }
    p {
      line-height: 35px;
      font-size: 1.25rem;

      padding: 1.25rem 0;
    }
    button {
      padding: 0 3.8rem;
    }
  }

  .peoples {
    flex: 3 1 360px;
    width: 280px;
    padding-top: 2rem;
    img {
      width: 100%;
    }
  }

  > div {
    flex: 1 1 360px;
  }

  @media (max-width: 960px) {
    flex-direction: column-reverse;

    padding: 4rem 18px;

    .peoples {
      width: 100%;
      max-width: 520px;
    }
  }

  @media (max-width: 520px) {
    p {
      color: var(--white-100);
    }
  }
}

.toast {
  width: 200px;
  background: #000;
  color: var(--white-100);
  height: 60px;
  transition: ease-in-out 0.5s;
}

.default {
  transform: translate3d(-200px,0,0);
}

.animation {
  transform: translate3d(0,0,0);
}

.aboutUs {
  gap: 4rem;
  align-items: center;

  padding-bottom: 0;
  .imagePurple {
    flex: 4 1 360px;
    transform: translate3d(0, -100px, 0);

    img {
      &:first-child {
        transform: translate3d(300px, 150px, 0) scale(0.8);
      }
    }
    .mainImg {
      max-width: 600px;
      max-height: 550px;
    }
    .objectsPurple {
      transform: translate3d(0, -200px, 0) scale(0.8);
    }
  }
  .aboutText {
    display: flex;
    flex-direction: column;
    gap: 2.2rem;
    flex: 3 1 360px;

    span {
      text-transform: uppercase;
      font: 700 1.2rem "Raleway", sans-serif;

      color: var(--secondary);
    }

    h2 {
      font-size: 2.5rem;
      color: var(--primary);
    }

    p {
      margin: 0;
    }
  }
  button {
    padding: 0 3.8rem;
  }

  @media (max-width: 960px) {
    height: auto;
    gap: 0;

    .imagePurple {
      text-align: center;

      padding: -1rem 1rem;
      transform: translate3d(0, 5px, 0);

      img {
        &:first-child {
          max-width: 260px;
          transform: translate3d(200px, 100px, 0) scale(0.6);
        }
      }

      .objectsPurple {
        transform: translate3d(0, 150px, 0);
      }
    }

    flex-direction: column;
    gap: 3rem;
  }

  @media (max-width: 620px) {
    padding: 0 1.5rem;

    .imagePurple {
      text-align: center;
      position: relative;

      padding: 0 1rem;

      img {
        &:first-child {
          max-width: 260px;
          transform: translate3d(100px, 100px, 0) scale(0.6);
        }
      }

      .mainImg {
        max-width: 400px;
        max-height: 350px;
      }
    }
  }
}

.containerColumn {
  max-width: var(--container-xl);
  margin: 0 auto;
  padding: 142px 24px;
  text-align: center;

  h2 {
    font-size: 2.5rem;
    color: var(--secondary);
  }
}

.offering {
  h2 {
    margin-bottom: 3.5rem;
  }

  > p {
    margin: 1rem auto 4rem auto;
    max-width: 60%;
  }

  @media (max-width: 860px) {
    p {
      max-width: 80%;
    }
    .solution {
      flex-direction: column;

      > div {
        max-width: 380px;
      }
    }
  }

  @media (max-width: 580px) {
    padding: 42px 24px;
    p {
      max-width: 95%;
    }
  }
}
