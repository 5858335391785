.headerContainer {
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 997;

  border-bottom: 1px solid #bbbec770;
}

//====== Menu Desktop ========

.menuDesktopContainer {
  transition: 0.5s ease;
  max-width: var(--container-xl);
  width: 100%;

  margin: 0 auto;
  padding: 0.75rem 1.25rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ul {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    > div {
      position: relative;

      display: flex;
      align-items: center;
      gap: 0.75rem;

      img {
        width: 36px;
        height: 36px;
      }
      select {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;

        option {
          background: var(--white-100);

          border-radius: 1rem;

          &:first-child {
            display: none;
          }
        }
      }
    }
  }

  span {
    button {
      height: 2.5rem;
    }
  }

  @media (max-width: 960px) {
    display: none;
  }
}

@media (min-width: 1330px) {
  .menuDesktopContainer {
    a {
      color: var(--primary);
    }
  }
}

.dropdownButton {
  background: transparent;
  border: none;
  color: var(--primary) !important;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  z-index: 99;
}

.dropdownContent {
  display: flex;
  flex-direction: column;
  background-color: var(--white-100);

  position: absolute;
  // top: 77px;
  border-radius: 0.25rem;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid #bbbec770;

  opacity: 0;
  transform: translate3d(0, -80px, 0);
  transition: 0.5s ease-out;

  &.open {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.navLink {
  padding: 0.3rem 0.5rem;
  color: var(--white-100);
  font-weight: 600;
  position: relative;

  &:hover {
    opacity: 0.9;
    &::after {
      width: 80%;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 92%;
    left: 0;
    width: 0;
    height: 2px;
    transition: 0.6s ease;
    transform: translate(12%, -50%);
    background-color: var(--primary);
  }
}

.showBackground {
  transition: 0.5s ease;
  background-color: var(--white-100);
  nav ul a {
    color: var(--gray-300);
  }
  ul button {
    color: var(--gray-300);
    background: transparent;
    border: none;
    position: relative;

    &:hover {
      transform: none;
      opacity: 0.9;
      &::after {
        width: 80%;
      }
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 80%;
      left: 0;
      width: 0;
      height: 2px;
      transition: 0.6s ease;
      transform: translate(12%, -50%);
      background-color: var(--primary);
    }
  }
}

//====== Menu Mobile ========

.navbarMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem 1.5rem;
  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .btnLanguage {
    background: transparent;
    border: none;
    z-index: 999;

    display: flex;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
    }
    select {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;

      option {
        background: var(--white-100);

        border-radius: 1rem;

        width: 30%;
        &:first-child {
          display: none;
        }
        &:hover {
          background-color: var(--secondary);
        }
      }
    }
  }

  .btnMenu {
    height: 36px;
    width: 36px;
    position: relative;

    border: none;
    background: transparent;

    z-index: 999;

    &:after,
    &:before {
      content: "";
      position: absolute;

      height: 3px;
      width: 70%;
      border-radius: 16px;

      background-color: var(--primary);
      transition: 0.8s ease;
    }

    &:before {
      top: 30%;
      left: 12%;
    }

    &:after {
      top: 60%;
      left: 12%;
    }
  }

  .bxActive {
    &:before {
      top: 50%;
      transform: rotate(-135deg);
    }
    &:after {
      top: 50%;
      transform: rotate(495deg);
    }
  }

  @media (min-width: 960px) {
    display: none;
  }
}

.menuMobileContainer {
  width: 100vw;
  height: 110vh;

  background-color: var(--white-200);

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  opacity: 0;
  pointer-events: none;
  z-index: 998;

  transform: translateY(-100px);
  transition: 0.7s ease;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;

    li span {
      display: flex;
      gap: 2rem;
      margin-top: 1rem;

      svg {
        transform: scale(1.4);
        transition: 0.5s ease;

        &:hover {
          transform: scale(1.4) translate3d(0, -6px, 0);
        }
      }
    }
  }

  .navLink {
    color: var(--gray-300);
    font-size: 1.4rem;
  }

  @media (min-width: 960px) {
    display: none;
  }
}

.showMenu {
  opacity: 1;
  pointer-events: all;
}
