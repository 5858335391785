.containerFlags {
  display: flex;
  gap: 0.5rem;
  button {
    background: none;
    border: none;

    img {
      width: 40px;
    }
  }

  @media (max-width: 720px) {
    img {
      width: 36px;
    }
  }
}
