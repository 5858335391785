.container {
  max-width: var(--container-xl);
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 142px 24px;
}

.scholarshipContent {
  flex-direction: column;
}

.swiper-container {
  padding: 2rem 0 !important;

  display: flex;
  align-items: center;
  justify-content: center;
}

.swiperContainerShip {
  padding: 1rem 0;
  max-width: 760px;
  width: 100%;
  gap: 2rem;
  display: flex;
  flex-direction: column;
}

.swiper-pagination {
  transform: translateY(10px);
}

.arrowSeparator {
  text-align: center;
  display: block;
  margin: 0 auto;
}
