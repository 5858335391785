.whyCam {
  text-align: center;

  p {
    text-align: center;
    max-width: 960px;
  }

  a {
    color: var(--secondary);
    font-weight: bold;
    font-size: 1rem;
    transition: 0.3s ease;

    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
}

.benefitsContainer {
  max-width: var(--container-xl);
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 92px 24px;
  grid-template-rows: repeat(3, 1fr);
}

.title {
  flex: 1 1 360px;

  h2 {
    font-size: 2.5rem;
    color: var(--primary);
    display: block;
  }

  svg {
    margin-left: auto;
  }
}

.groupGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  flex: 3 1 360px;
  grid-gap: 1rem;

  li {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    border-bottom: 1px solid #6a727f4c;
    padding-bottom: 1rem;
  }
}

.restrictionsContainer {
  max-width: var(--container-xl);
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 92px 24px;

  .title {
    text-align: right;
    svg {
      transform: rotate(180deg);
    }
  }
}
