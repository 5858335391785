.footerContainer {
  background-color: var(--gray-700);
  > div {
    &:first-child {
      margin: 0 auto;
      padding: 2.5rem 1.5rem;
      max-width: var(--container-xl);
      display: grid;
      grid-template-columns: 2fr 1.5fr 2fr;
      grid-template-rows: 1fr;

      span {
        font-weight: 700;
        color: var(--white-100);
      }

      color: var(--gray-300);
    }
    &:last-child {
      background-color: var(--gray-800);

      div {
        margin: 0 auto;
        padding: 2.5rem 1.5rem;
        max-width: var(--container-xl);

        a {
          color: var(--gray-200);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  @media (max-width: 690px) {
    > div {
      &:first-child {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
      }
    }
  }
}

.infoCamara {
  img {
    margin-bottom: 1.5rem;
  }
  span {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    a {
      display: inline-block;
      padding: 0.25rem;
      transition: 0.5s ease;
    }
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    margin-top: 1.5rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    a {
      font-weight: 600;
      color: var(--gray-300);

      &:hover {
        text-decoration: underline;
      }
    }
  }
  @media (max-width: 690px) {
    display: none;
  }
}

.contact {
  ul {
    margin-top: 1.5rem;
    &:first-child {
      li + li {
        margin-top: 0.75rem;
      }
    }
    &:last-child {
      li + li {
        margin-top: 1.5rem;
      }
    }
  }

  select {
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    border-bottom: 2px solid var(--secondary);
    font-family: inherit;
    color: var(--white-100);
    font-size: inherit;
    cursor: pointer;
    line-height: inherit;

    &:focus {
      border-bottom: 2px solid var(--gray-300);
    }
    
    option {
      background: var(--gray-600);
      border-radius: 1rem;

      &:hover {
        background-color: var(--secondary);
      }
    }
  }
}
