.container {
  max-width: var(--container-xl);
  margin: 0 auto;

  display: flex;

  h2 {
    font-size: 2.5rem;
    color: var(--gray-600);
  }
}

.containerProduction {
  max-width: var(--container-xl);
  margin: 0 auto;

  display: flex;

  h2 {
    font-size: 2.5rem;
    color: var(--gray-600);
  }
}

.containerColumn {
  max-width: var(--container-xl);
  margin: 0 auto;
  padding: 142px 24px;
  text-align: center;

  h2 {
    font-size: 2.5rem;
    color: var(--secondary);
  }
}

.heroContainer {
  background-color: var(--gray-600);

  > div {
    max-width: var(--container-xl);
    margin: 0 auto;

    padding: 200px 24px 70px 24px;

    h1 {
      color: var(--white-100);
      font-weight: 700;
      font-size: 3rem;
      margin-bottom: 1rem;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
    }

    a {
      color: var(--white-200);
      opacity: 0.8;
      transition: 0.5s ease;

      &:hover {
        opacity: 1;
        color: var(--white-200);
      }

      + a {
        margin-left: 1.5rem;
      }
    }
  }
}

.production {
  gap: 8rem;
  width: 100%;
  padding: 2.25rem 1.5rem;

  justify-content: space-between;

  .prodText {
    flex: 3 1 360px;

    p {
      margin-top: 1.5rem;
      text-align: justify;
      line-height: 1.6rem;
    }
  }
  .prodImage {
    flex: 2 1 360px;
    max-width: 570px;
    height: 540px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      box-shadow: 0 45px 30px rgb(158 178 183 / 40%);
    }
  }

  @media (max-width: 960px) {
    gap: 4rem;
    align-items: center;

    flex-direction: column-reverse;
  }
}

.ourMethodologies {
  padding: 8rem 2rem;

  gap: 3rem;

  h2 {
    margin-bottom: 4rem;
  }

  .flexCards {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0.75rem;

      background-color: var(--white-100);

      box-shadow: 0px 4px 8px 2px rgba(20, 20, 94, 0.08),
        -1px -1px 4px rgba(20, 20, 94, 0.08);

      border-radius: 0.75rem;
      padding: 2.5rem 1.25rem;
      max-width: 340px;
      height: 320px;

      span {
        font-weight: 700;
        font-size: 1.25rem;
        color: var(--gray-600);
      }
    }
  }

  @media (max-width: 1110px) {
    .flexCards {
      flex-direction: column;
      gap: 1.25rem;
      > div {
        max-width: 60%;
      }
    }
  }

  @media (max-width: 520px) {
    .flexCards {
      > div {
        max-width: 90%;
        padding: 1rem 0.75rem;
        height: 260px;
      }
    }
  }
}

.benefits {
  gap: 4rem;
  > div {
    padding: 1rem 2.5rem;
    text-align: center;
    width: 33.333%;

    svg {
      max-width: 220px;
    }

    p {
      text-align: center;
    }

    h4 {
      color: var(--gray-800);
      margin: 1.25rem;
      font-weight: 700;
      font-size: 1.15rem;
    }
  }

  @media (max-width: 960px) {
    gap: 1rem;
    align-items: center;
    flex-direction: column;
    > div {
      width: 90%;
    }
  }
}

.ourPartners {
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;

  padding: 6rem 2rem 3rem 2rem;

  h2 {
    font-size: 2.5rem;
    color: var(--gray-600);
    text-align: center;
  }

  > div {
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
    justify-content: center;

    a {
      max-width: 240px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s ease;
      cursor: pointer;

      &:hover {
        transform: translate3d(0, -20px, 0);
      }

      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 560px) {
    > div {
      gap: 1rem;
      div {
        max-width: 160px;
      }
    }
  }
}

.sectionVideos {
  justify-content: center;
  gap: 6rem;
  flex-wrap: wrap;

  padding: 6rem 1.5rem;

  iframe {
    border-radius: 8px;
    max-width: 600px;
    height: 400px;
    box-shadow: 0px 4px 8px 2px rgba(20, 20, 94, 0.08),
      -1px -1px 4px rgba(20, 20, 94, 0.08);
  }

  @media (max-width: 720px) {
    video {
      max-width: 500px;
      max-height: 300px;
    }
  }

  > div {
    height: 290px;

    iframe {
      height: 100%;
      border-radius: 8px;
    }
  }

  @media (max-width: 690px) {
    gap: 3rem;
  }
}
