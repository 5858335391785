.container {
  max-width: var(--container-xl);
  margin: 0 auto;

  display: flex;

  h2 {
    font-size: 2.5rem;
    color: var(--gray-600);
  }

  p {
    margin: 1.5rem 0 3rem 0;
    text-align: justify;
  }
}

.heroContainer {
  > div {
    max-width: var(--container-xl);
    margin: 0 auto;

    padding: 200px 24px 70px 24px;

    h1 {
      color: var(--white-100);
      font-weight: 700;
      font-size: 3rem;
      margin-bottom: 1rem;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
    }

    a {
      color: var(--white-200);
      opacity: 0.8;
      transition: 0.5s ease;

      &:hover {
        opacity: 1;
        color: var(--white-200);
      }

      + a {
        margin-left: 1.5rem;
      }
    }
  }
}

.manyOptions {
  gap: 12rem;
  width: 100%;
  padding: 2.25rem 1.5rem;

  justify-content: space-between;
  align-items: center;

  .optionsText {
    flex: 3 1 360px;
  }

  .optionsImages {
    flex: 2 1 360px;

    position: relative;

    img {
      border-radius: 8px;
      &:last-child {
        box-shadow: 0 45px 30px rgb(158 178 183 / 40%);
        position: absolute;
        top: 140px;
        left: -120px;

        z-index: 3;
      }
    }
  }

  @media (max-width: 920px) {
    flex-direction: column-reverse;
    gap: 4rem;

    .optionsImages {
      padding: 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1.5rem;

      img {
        max-width: 100%;
        &:last-child {
          position: static;
          max-width: 80%;
        }
      }
    }
  }
}

.aboutUsGroup {
  gap: 2rem;
  width: 100%;
  padding: 6rem 1.5rem;
  margin-top: 3rem;

  justify-content: space-between;

  .groupImages {
    flex: 5 1 360px;
    display: flex;
    padding: 0 1rem;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;

    gap: 1.25rem;

    p {
      margin: 0;
    }

    .flex1 {
      > div {
        background: var(--white-100);

        max-width: 260px;
        width: 100%;
        border-radius: 0.7rem;
        padding: 4rem;
        transition: background-color 0.8s;

        box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.2);

        p {
          margin-top: 1rem;
        }

        &:hover {
          background-color: var(--primary);
          svg path {
            stroke: var(--white-100);
          }
        }
      }
    }
    .flex2 {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      > div {
        background: var(--white-100);
        text-align: center;
        border-radius: 0.7rem;

        display: flex;
        box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.2);
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: background-color 0.8s;

        p {
          margin-top: 1rem;
        }

        &:hover {
          background-color: var(--primary);
          svg path,
          svg circle {
            stroke: var(--white-100);
          }
        }
      }
      .item2 {
        max-width: 380px;
        width: 100%;
        padding: 4rem 6rem;
      }
      .item3 {
        max-width: 220px;
        width: 100%;
        padding: 6rem 3rem;
      }
    }
  }

  .groupText {
    flex: 3 1 360px;

    h2 {
      color: var(--secondary);
    }
  }

  @media (max-width: 920px) {
    flex-direction: column;
  }

  @media (max-width: 560px) {
    .groupImages {
      flex-wrap: nowrap;
      svg {
        width: 60px;
      }
      .flex1 {
        > div {
          max-width: 310px;
          padding: 2rem 3rem;
        }
      }
      .flex2 {
        gap: 1.25rem;
        .item2 {
          max-width: 310px;
          width: 100%;
          padding: 2rem 3rem;
        }
        .item3 {
          max-width: 200px;
          width: 100%;
          padding: 2rem 3rem;
        }
      }
    }
  }
}
