* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white-100: #ffffff;
  --white-200: #f3f6fa;

  --primary: #14145e;
  --secondary: #f25d23;

  --gray-200: #485265;
  --gray-300: #6a727f;
  --gray-600: #393e46;
  --gray-700: #282f3a;
  --gray-800: #252b36;

  --container-xl: 1260px;
  --container-m: 960px;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

body {
  background: var(--white-200);
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body,
input,
textarea,
button {
  font: 600 1rem "Open sans", sans-serif;

  color: var(--gray-200);
}

p {
  line-height: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  color: var(--gray-800);
}

h2 {
  font-size: 1.5rem;
  font-weight: 800;
  color: var(--primary);
}

button {
  cursor: pointer;
}

a,
li {
  text-decoration: none;
  list-style: none;
}

.container {
  max-width: var(--container-xl);
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 142px 24px;
}

::-webkit-scrollbar {
  width: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9b9be7;
  border-radius: 10px;
  height: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8686d6;
}
