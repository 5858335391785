.swiperContainer {
  padding-bottom: 3rem;
}

.despoilment {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;

  padding: 1rem 3.5rem;

  span {
    display: block;
  }
  .rounded{
    border-radius: 50%;
  }

  .slideText {
    text-align: left;
    p {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 40px;
      color: var(--gray-600);
    }

    span {
      color: var(--gray-400);
      font-weight: 700;
    }
  }

  .slideImage {
    position: relative;

    img:first-child {
      max-width: 320px;
    }

    img:last-child {
      position: absolute;
      top: 30%;
      right: 0;
    }
  }

  @media (max-width: 820px) {
    gap: 3rem;
    padding: 1rem;

    .slideText {
      p {
        font-size: 1.25rem;
        line-height: 32px;
      }
    }

    .slideImage {
      img:first-child {
        max-width: 220px;
      }
      img:last-child {
        max-width: 80px;
      }
    }
  }

  @media (max-width: 560px) {
    flex-direction: column;
    gap: 1rem;
    .slideText {
      p {
        font-size: 1rem;
        line-height: 24px;
      }
    }

    .slideImage {
      img:first-child {
        max-width: 180px;
      }
      img:last-child {
        max-width: 60px;
      }
    }
  }
}